// third party imports
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// internal rebet imports
import './stylesFinancialsGraph.css'
import SortByDate from '../../../../utils/SortByDate';
import DropDown from '../../../CommonComponents/DropDown/DropDown';
import DatePicker from 'react-datepicker';
import DropdownMultipleCheckbox from '../../../CommonComponents/DropdownMultipleCheckbox/DropdownMultipleCheckbox';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { is } from 'date-fns/locale';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';
import { format, parse } from 'date-fns';

// component imports

// asset imports

const FinancialsGraph = ({referralCodes}) => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);
  const optionsCode = referralCodes;

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [graphData, setGraphData] = useState({});

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (selectAll, newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
    getRevenueData(selectAll, newSelectedOptions);
  };

  const getRevenueData = async (selectAll, options) => {
    setIsApiRunning(true);

    const params = {};
    if (options.length === 0 || selectAll) {
      params.all_referral_codes = true;
    } else if (options.length === 1 && !selectAll) {
      params.referral_code = options[0];
    } else {
      params.list_of_referral_codes = options;
    }

    try {
      const revenueDataResponse = await PrivateAdminPanelInstance?.post(API_ROUTES.AVG_MONTHLY_REVENUE, params)
      const revenueData = revenueDataResponse.data.data;

      setGraphData(revenueData);
      setIsApiRunning(false);
    } catch (error) {
      setIsApiRunning(false);
      console.log("error Revenue Earned data", error);
    }
  };

  useEffect(() => {
    getRevenueData(true, []);
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const sortedByDateData = SortByDate(graphData);

  const months = Object.keys(sortedByDateData);
  const revenue = Object.values(sortedByDateData).map(value => parseFloat(value));
  
  const data = {
    labels: months, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'Revenue',
        data: revenue,
        backgroundColor: '#FD6F0F',
        borderRadius: 5,
      },
    ],
  };
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'category',
        stacked: false,
        ticks: {
          color: '#FFF',
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        display: true,
        position: 'left',
        stacked: false,
        ticks: {
          color: '#FFF',
          callback: function (value) {
            return value > 1000 ? value / 1000 + 'k' : value;
          },
        },
      },
    },
  };


  return (
    <div className='financials-graph-container'>
      <div className="financials-graph">
        {isApiRunning ?
          <RebetLoader
            height={'70px'}
            width={'70p'}
          />
          :
          <>
            <div className="financials-graph-header-row">
              <div className="financials-graph-header-label">
                Revenue Earned
              </div>
              <div className='right-half-graph-header'>
                <DropdownMultipleCheckbox options={optionsCode}
                  selectedOptions={selectedOptions}
                  onChange={handleCheckboxChange} />
              </div>
            </div>
            <div className="financials-data-graph">
              <Bar data={data} options={options} />
            </div>
          </>
        }

      </div>
    </div>
  );
};

export default FinancialsGraph;
