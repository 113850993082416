import { useMemo } from "react";
import { FREE_BET_TYPES, TIME_FRAMES } from "../utils/Constants";
import { formatDateDay, formatDateMonth } from "../utils/DateFormatter";
import moneyFormatter from "../utils/MoneyFormatter";

const useTrackingChartData = ({
  selectedTimeFrame,
  byDay,
  byMonth,
  selectedDataType = FREE_BET_TYPES.WAGERED,
}) => {
  const dataType = selectedDataType === FREE_BET_TYPES.WAGERED ? "wager" : "payout";
  const data = useMemo(() => {
    const labels =
      selectedTimeFrame === TIME_FRAMES.DAY
        ? Object.keys(byDay)
        : Object.keys(byMonth);

    const formattedLabels = labels.map((date) =>
      selectedTimeFrame === TIME_FRAMES.DAY
        ? formatDateDay(date, false)
        : formatDateMonth(date)
    );

    const sportradarData = labels.map((date) =>
      selectedTimeFrame === TIME_FRAMES.DAY
        ? parseFloat(byDay[date][dataType]?.sportradar)
        : parseFloat(byMonth[date][dataType]?.sportradar)
    );

    const dstData = labels.map((date) =>
      selectedTimeFrame === TIME_FRAMES.DAY
        ? parseFloat(byDay[date][dataType]?.dst)
        : parseFloat(byMonth[date][dataType]?.dst)
    );

    const data = {
      labels: formattedLabels,
      datasets: [
        {
          label: "SportRadar",
          data: sportradarData,
          backgroundColor: "#9B59B6",
          borderColor: "#9B59B6",
          borderWidth: 1,
          borderRadius: 5,
        },
        {
          label: "DST",
          data: dstData,
          backgroundColor: "#71BF8A",
          borderColor: "#71BF8A",
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    };

    return data;
  }, [selectedTimeFrame, byDay, byMonth, selectedDataType]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: function (context) {
            let tooltipEl = document.getElementById("freebet-chartjs-tooltip");
            const typeData = selectedDataType === FREE_BET_TYPES.WAGERED ? "Wagered" : "Paid Out";

            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "freebet-chartjs-tooltip";
              tooltipEl.innerHTML = "<table></table>";
              document.body.appendChild(tooltipEl);
            }

            const tooltipModel = context.tooltip;

            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add("no-transform");
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = "<thead>";

              titleLines.forEach(function (title) {
                innerHtml += "<tr><th>" + title + " - " + typeData + "</th></tr>";
              });
              innerHtml += "</thead><tbody>";

              let total = 0;

              bodyLines.forEach(function (body, i) {
                const split = body[0].split(": ");
                const customBody = split[0] + ": $" + split[1];
                const colors = tooltipModel.labelColors[i];
                const style = `background:${colors.backgroundColor}; border-color:${colors.borderColor}; border-width: 2px; width: 10px; height: 10px; display: inline-block; border-radius: 50%; margin-right: 5px;`;
                const span = `<span style="${style}"></span>`;
                const value = parseFloat(
                  body[0].split(": ")[1].replace(/,/g, "")
                );

                total += value;
                innerHtml += `<tr><td>${span}${customBody}</td></tr>`;
              });

              innerHtml += `<tr><td>Total: $${moneyFormatter(total)}</td></tr>`;
              innerHtml += "</tbody>";

              const tableRoot = tooltipEl.querySelector("table");
              tableRoot.innerHTML = innerHtml;
            }

            const position = context.chart.canvas.getBoundingClientRect();

            tooltipEl.style.opacity = 0.9;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = position.left + tooltipModel.caretX + "px";
            tooltipEl.style.top = position.top + tooltipModel.caretY + "px";
            tooltipEl.style.fontFamily = tooltipModel.options.bodyFont.family;
            tooltipEl.style.fontSize =
              tooltipModel.options.bodyFont.size + "px";
            tooltipEl.style.fontStyle = tooltipModel.options.bodyFont.style;
            tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
            tooltipEl.style.pointerEvents = "none";
          },
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          barThickness: 5,
          ticks: {
            color: "#92929E",
            callback: function (value, index, ticks) {
              if (ticks.length > 10) {
                return index % 3 === 0 ? this.getLabelForValue(value) : "";
              }
              return this.getLabelForValue(value);
            },
          },
        },
        y: {
          beginAtZero: true,
          stacked: true,
          ticks: {
            callback: function (value) {
              return value / 1000 + "k";
            },
          },
        },
      },
      interaction: {
        mode: "index",
        intersect: false,
      },
    }),
    [selectedDataType]
  );

  return { data, options };
};

export default useTrackingChartData;
