import React, { useEffect } from "react";

import ToggleSwitch from "../../../../../../CommonComponents/ToggleSwitch/ToggleSwitch";

import { PERSONAL_LIMITS } from "../../../../../../../utils/Constants";
import moneyFormatter from "../../../../../../../utils/MoneyFormatter";
import CashIcon from "../../../../../../../assets/icons/ic_cash.png";
import CoinIcon from "../../../../../../../assets/icons/ic_coin.png";
import RebetLoader from "../../../../../../CommonComponents/RebetLoader/RebetLoader";

// import { Container } from './styles';

function PersonalLimits({
  userPersonalLimit,
  setTitle,
  setIsViewPersonalLimitsPopupVisible,
  setIsEditTimeLimitsPopupVisible,
  setIsEditCurrencyLimitsPopupVisible,
  setIsEditWaggerLimitsPopupVisible,
  setIsEditLossLimitsPopupVisible,
  getUserPersonalLimit,
  isApiRunning,
  setEditingMode
}) {
  const handleEditClick = (section) => {
    setEditingMode(true);
    switch (section) {
      case PERSONAL_LIMITS.TIME_LIMITS:
        setTitle("Edit Time Limits");
        setIsViewPersonalLimitsPopupVisible(false);
        setIsEditTimeLimitsPopupVisible(true);
        break;
      case PERSONAL_LIMITS.CURRENCY_LIMITS:
        setTitle("Edit Currency Limits");
        setIsViewPersonalLimitsPopupVisible(false);
        setIsEditCurrencyLimitsPopupVisible(true);
        break;
      case PERSONAL_LIMITS.WAGGER_LIMITS:
        setTitle("Edit Wagger Limits");
        setIsViewPersonalLimitsPopupVisible(false);
        setIsEditWaggerLimitsPopupVisible(true);
        break;
      case PERSONAL_LIMITS.LOSS_LIMITS:
        setTitle("Edit Loss Limits");
        setIsViewPersonalLimitsPopupVisible(false);
        setIsEditLossLimitsPopupVisible(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getUserPersonalLimit(false);
  }, []);

  return (
    <div className="personal-limits-body">
      {isApiRunning ? (
        <RebetLoader />
      ) : (
        <>
          <div className="personal-limits-section-container">
            <div className="personal-limits-section-header">
              Time limits
              <span
                className="personal-limits-edit-button"
                onClick={() => handleEditClick(PERSONAL_LIMITS.TIME_LIMITS)}
              >
                Edit
              </span>
            </div>
            <div className="personal-limits-section-body">
              <div className="personal-limits-section-item border-bottom">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max numbers of days you play <br /> the games per week
                  </div>
                  <div className="personal-limits-section-item-value">
                    {userPersonalLimit.max_playing_time_per_week !== false
                      ? `${userPersonalLimit.max_playing_time_per_week} days`
                      : "-"}
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_playing_time_per_week}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
              <div className="personal-limits-section-item">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max time you spend playing per day
                  </div>
                  <div className="personal-limits-section-item-value">
                    {userPersonalLimit.max_playing_time_per_day !== false
                      ? `${userPersonalLimit.max_playing_time_per_day} hours`
                      : "-"}
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_playing_time_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="personal-limits-section-container">
            <div className="personal-limits-section-header">
              Currency limits
              <span
                className="personal-limits-edit-button"
                onClick={() => handleEditClick(PERSONAL_LIMITS.CURRENCY_LIMITS)}
              >
                Edit
              </span>
            </div>
            <div className="personal-limits-section-body">
              <div className="personal-limits-section-item border-bottom">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max purchase amount of <br /> Rebet Coins per day
                  </div>
                  <div className="personal-limits-section-item-value">
                    <img
                      src={CoinIcon}
                      alt="Coin Icon"
                      width={15}
                      height={15}
                    />
                    <span>
                      {userPersonalLimit.max_coin_purchase_per_day !== false
                        ? moneyFormatter(
                            userPersonalLimit.max_coin_purchase_per_day
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_coin_purchase_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
              <div className="personal-limits-section-item">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max Rebet Cash bonus per day
                  </div>
                  <div className="personal-limits-section-item-value">
                    <img
                      src={CashIcon}
                      alt="Cash Icon"
                      width={15}
                      height={15}
                    />
                    <span>
                      {userPersonalLimit.max_cash_bonus_per_day !== false
                        ? moneyFormatter(
                            userPersonalLimit.max_cash_bonus_per_day
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_cash_bonus_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="personal-limits-section-container">
            <div className="personal-limits-section-header">
              Wagger limits
              <span
                className="personal-limits-edit-button"
                onClick={() => handleEditClick(PERSONAL_LIMITS.WAGGER_LIMITS)}
              >
                Edit
              </span>
            </div>
            <div className="personal-limits-section-body">
              <div className="personal-limits-section-item border-bottom">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max purchase amount of Rebet Coins can <br /> be wagered per
                    day
                  </div>
                  <div className="personal-limits-section-item-value">
                    <img
                      src={CoinIcon}
                      alt="Coin Icon"
                      width={15}
                      height={15}
                    />
                    <span>
                      {userPersonalLimit.max_coin_wagered_per_day !== false
                        ? moneyFormatter(
                            userPersonalLimit.max_coin_wagered_per_day
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_coin_wagered_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
              <div className="personal-limits-section-item">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max amount of Rebet Cash can <br /> be wagered per day
                  </div>
                  <div className="personal-limits-section-item-value">
                    <img
                      src={CashIcon}
                      alt="Cash Icon"
                      width={15}
                      height={15}
                    />
                    <span>
                      {userPersonalLimit.max_cash_wagered_per_day !== false
                        ? moneyFormatter(
                            userPersonalLimit.max_cash_wagered_per_day
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_cash_wagered_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="personal-limits-section-container">
            <div className="personal-limits-section-header">
              Loss limits
              <span
                className="personal-limits-edit-button"
                onClick={() => handleEditClick(PERSONAL_LIMITS.LOSS_LIMITS)}
              >
                Edit
              </span>
            </div>
            <div className="personal-limits-section-body">
              <div className="personal-limits-section-item border-bottom">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max amount of Rebet Coins you <br />
                    will allow yourself to lose each day
                  </div>
                  <div className="personal-limits-section-item-value">
                    <img
                      src={CoinIcon}
                      alt="Coin Icon"
                      width={15}
                      height={15}
                    />
                    <span>
                      {userPersonalLimit.max_coin_loss_per_day !== false
                        ? moneyFormatter(
                            userPersonalLimit.max_coin_loss_per_day
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_coin_loss_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
              <div className="personal-limits-section-item">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max amount of Rebet Cash you <br />
                    will allow yourself to lose each day
                  </div>
                  <div className="personal-limits-section-item-value">
                    <img
                      src={CashIcon}
                      alt="Cash Icon"
                      width={15}
                      height={15}
                    />
                    <span>
                      {userPersonalLimit.max_cash_loss_per_day !== false
                        ? moneyFormatter(
                            userPersonalLimit.max_cash_loss_per_day
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_cash_loss_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PersonalLimits;
