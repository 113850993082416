import React, { useEffect, useState } from "react";
import RebetLoader from "../../../CommonComponents/RebetLoader/RebetLoader";

import "./stylesAltOddsTable.css";
import AltOddsTableRow from "./AltOddsTableRow/AltOddsTableRow";
import createPrivateAdminPanelInstance from "../../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../../api/ApiRoutes";
import "react-datepicker/dist/react-datepicker.css";

function AltOddsTable({ user_id = "" }) {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [data, setData] = useState([]);

  const getAltOddsTable = async () => {
    setIsApiRunning(true);

    const params = { user_id };

    try {
      const response = await PrivateAdminPanelInstance?.post(
        API_ROUTES?.GET_ALT_ODDS_HISTORY,
        params
      );
      const data = response?.data?.data;

      setData(data);
      setIsApiRunning(false);
    } catch (error) {
      console.log("error getting Alt-Odds", error);
    } finally {
      setIsApiRunning(false);
    }
  };

  useEffect(() => {
    getAltOddsTable(user_id);
  }, []);

  return (
    <div className="alt-odds-table-container">
      <div className="alt-odds-table">
        <>
          <div className="head-controls">
            <div className="header-text-container">
              <p className="header-text">Alt Odds</p>
            </div>
          </div>

          <div className="table-container">
            {isApiRunning ? (
              <RebetLoader height={"100px"} width={"100px"} />
            ) : (
              <>
                <AltOddsTableRow is_header_row={true} data={{}} />

                {data &&
                  data.map((data, index) => (
                    <AltOddsTableRow
                      key={index}
                      is_header_row={false}
                      data={data}
                    />
                  ))}
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
}

export default AltOddsTable;
