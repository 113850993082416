// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesSider.css'
import { SIDER_TABS } from '../../../utils/Constants';
import { NAVIGATION_ROUTES } from '../../../utils/NavigationUtils';
import { API_ROUTES } from '../../../api/ApiRoutes';
import { ADMIN_TYPES } from '../../../utils/Constants';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';

// component imports
import SiderTab from './SiderTab/SiderTab'

// asset imports
import WhiteUsersIcon from '../../../assets/icons/ic_users_white.png'
import GreyUsersIcon from '../../../assets/icons/ic_users_grey.png'
import WhiteSportsboookIcon from '../../../assets/icons/ic_sportsbook_white.png'
import GreySportsbookIcon from '../../../assets/icons/ic_sportsbook_grey.png'
import WhiteKPIsIcon from '../../../assets/icons/ic_kpis_white.png'
import GreyKPIsIcon from '../../../assets/icons/ic_kpis_grey.png'
import WhiteAdminIcon from '../../../assets/icons/ic_lock_white.png'
import GreyAdminIcon from '../../../assets/icons/ic_lock_grey.png'
import WhiteAffiliateIcon from '../../../assets/icons/ic_affiliate_white.png'
import GreyAffiliateIcon from '../../../assets/icons/ic_affiliate_grey.png'
import WhiteEventsIcon from '../../../assets/icons/ic_event_white.png'
import GreyEventsIcon from '../../../assets/icons/ic_event_grey.png'
import PromotionsIcon from '../../../assets/icons/ic_promotion.svg'
import StaffManagementIcon from '../../../assets/icons/ic_staff_management.svg'

const Sider = ({
  selectedTab,
  isExpanded,
}) => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)

  // state variables
  const [adminType, setAdminType] = useState("")
  const [apiDoneRunning, setAPIDonRunning] = useState(false)

  // use effects
  useEffect(() => {
    getLoggedInAdminsPermissions()
  }, [])

  // api functions
  const getLoggedInAdminsPermissions = async () => {
    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_PERMISSIONS)

      setAdminType(response?.data?.data?.group_name)

      setAPIDonRunning(true)

    } catch (error) {
      console.log("error retrieving admins permissions", error?.response)
      setAPIDonRunning(true)
    }
  }

  // helper functions
  const handleUsersPressed = () => {
    navigate(NAVIGATION_ROUTES?.USERS)
  }

  const handleSportsbookPressed = () => {
    navigate(NAVIGATION_ROUTES?.SPORTSBOOK)
  }

  const handleKPIsPressed = () => {
    navigate(NAVIGATION_ROUTES?.KPIS)
  }

  const handleAdminPressed = () => {
    navigate(NAVIGATION_ROUTES?.ADMIN)
  }

  const handleAffiliatesPressed = () => {
    navigate(NAVIGATION_ROUTES?.AFFILIATES)
  }

  const handleCodesPressed = () => {
    navigate(NAVIGATION_ROUTES?.EVENTS)
  }
  
  const handleWhiteListPressed = () => {
    navigate(NAVIGATION_ROUTES?.STAFF_MANAGEMENT)
  }

  const handlePromotionsPressed = () => {
    navigate(NAVIGATION_ROUTES?.PROMOTIONS)
  }


  return (
    <div className={`sider ${isExpanded ? 'expanded' : 'collapsed'}`}>
      {/* Content for the sider can go here */}

      <div className='sider-buttons'>
        {
          adminType === ADMIN_TYPES?.CUSTOMER_SUPPORT || adminType === ADMIN_TYPES?.SUPER_ADMIN
            ? (
              <SiderTab
                selectedIcon={WhiteUsersIcon}
                unselectedIcon={GreyUsersIcon}
                tabName={SIDER_TABS?.USERS}
                selectedTab={selectedTab}
                onPress={handleUsersPressed}
              />
            ) : (
              null
            )
        }


        {
          adminType === ADMIN_TYPES?.CUSTOMER_SUPPORT || adminType === ADMIN_TYPES?.SUPER_ADMIN
            ? (
              <SiderTab
                selectedIcon={WhiteKPIsIcon}
                unselectedIcon={GreyKPIsIcon}
                tabName={SIDER_TABS?.KPIS}
                selectedTab={selectedTab}
                onPress={handleKPIsPressed}
              />
            ) : (
              null
            )
        }

        {
          adminType === ADMIN_TYPES?.CUSTOMER_SUPPORT || adminType === ADMIN_TYPES?.SUPER_ADMIN
            ? (
              <SiderTab
                selectedIcon={WhiteAffiliateIcon}
                unselectedIcon={GreyAffiliateIcon}
                tabName={SIDER_TABS?.AFFILIATE}
                selectedTab={selectedTab}
                onPress={handleAffiliatesPressed}
              />
            ) : (
              null
            )
        }

        {
          adminType === ADMIN_TYPES?.CUSTOMER_SUPPORT || adminType === ADMIN_TYPES?.SUPER_ADMIN
            ? (
              <SiderTab
                selectedIcon={PromotionsIcon}
                unselectedIcon={PromotionsIcon}
                tabName={SIDER_TABS?.PROMOTIONS}
                selectedTab={selectedTab}
                onPress={handlePromotionsPressed}
              />
            ) : (
              null
            )
        }
        
        {
          adminType === ADMIN_TYPES?.CUSTOMER_SUPPORT || adminType === ADMIN_TYPES?.SUPER_ADMIN
            ? (
              <SiderTab
                selectedIcon={WhiteEventsIcon}
                unselectedIcon={GreyEventsIcon}
                tabName={SIDER_TABS?.EVENTS}
                selectedTab={selectedTab}
                onPress={handleCodesPressed}
              />
            ) : (
              null
            )
        }

        {
          adminType === ADMIN_TYPES?.CUSTOMER_SUPPORT || adminType === ADMIN_TYPES?.SUPER_ADMIN
            ? (
              <SiderTab
                selectedIcon={StaffManagementIcon}
                unselectedIcon={StaffManagementIcon}
                tabName={SIDER_TABS?.STAFF_MANAGEMENT}
                selectedTab={selectedTab}
                onPress={handleWhiteListPressed}
              />
            ) : (
              null
            )
        }

        {
          adminType === ADMIN_TYPES?.SUPER_ADMIN
            ? (
              <SiderTab
                selectedIcon={WhiteAdminIcon}
                unselectedIcon={GreyAdminIcon}
                tabName={SIDER_TABS?.ADMIN}
                selectedTab={selectedTab}
                onPress={handleAdminPressed}
              />
            ) : (
              null
            )
        }
        {/* <SiderTab
        selectedIcon={WhiteSportsboookIcon}
        unselectedIcon={GreySportsbookIcon}
        tabName={SIDER_TABS?.SPORTSBOOK}
        selectedTab={selectedTab}
        onPress={handleSportsbookPressed}
      /> */}

      </div>
    </div>
  );
};

export default Sider;

