import React, { useState } from "react";
import createPrivateAdminPanelInstance from "../../../api/PrivateAdminPanelInstance";

import "./stylesAddNewIpPopup.css";
import { X } from "react-feather";
import { API_ROUTES } from "../../../api/ApiRoutes";

function AddNewIpPopup({
  isVisible,
  onClosePressed,
  getAdmins,
  selectedAdmin,
}) {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [ipAddress, setIpAddress] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const buttonDisabled = ipAddress.length <= 0 ? true : false;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setErrorMessage("");

    const params = {
      ip_address: ipAddress,
      admin_id: selectedAdmin.admin_id,
      action: "add",
    };

    try {
      await PrivateAdminPanelInstance?.post(API_ROUTES?.WHITELIST_IP, params);
      setSuccessMessage("IP added successfully!");

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);

      setIpAddress("");
      await getAdmins();
    } catch (error) {
      console.log("Error adding IP:", error);
      setErrorMessage("Error adding IP. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClosePressed = () => {
    onClosePressed();
    setIpAddress("");
    setErrorMessage("");
  };

  if (!isVisible) return null;
  return (
    <div className="add-new-ip-popup">
      <div className="add-new-ip-panel">
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="add-new-ip-panel-header">
          <p>Add new IP to: {selectedAdmin.admin_name}</p>
          <button className="close-button" onClick={handleClosePressed}>
            <X className="close-icon" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="ipAddress">IP Address:</label>
            <input
              type="text"
              id="ipAddress"
              value={ipAddress}
              placeholder="Enter IP Address"
              onChange={(e) => setIpAddress(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            disabled={buttonDisabled}
            className={`submit-button ${
              buttonDisabled || isSubmitting ? "disabled" : null
            }`}
          >
            {isSubmitting ? "Adding IP..." : "Add IP"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddNewIpPopup;
