// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal rebet imports
import './stylesUserActions.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';
import { API_ROUTES } from '../../../../api/ApiRoutes';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

// asset imports


const UserActions = ({
  userData,
  onManageMoneyPressed,
  onSuspendUserPressed,
  onToggleACHPressed,
  onEditUserDataPressed,
  onEditPersonalLimitsPressed,
  onEditFreeBetPressed,
  onEditProfitBoostPressed,
  onPaymentInstrumentsPressed,
  onDeleteUserPressed,
}) => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)

  // state variables
  const [emailVerificationSending, setEmailVerificationSending] = useState(false)
  
  // use effects

  // api functions
  const sendEmailVerification = async () => {

    setEmailVerificationSending(true)
    
    const params = {
      user_id: userData?.user_id, 
      email: userData?.email
    }

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.SEND_EMAIL_VERIFICATION, params)

      setEmailVerificationSending(false)
       
    } catch (error) {
      console.log("error sending email verification code", error?.response)
      setEmailVerificationSending(false)
    }

  }

  // helper functions
  const handleManageMoneyPressed = () => {
    onManageMoneyPressed()
  }

  const handleSuspendUserPressed = () => {
    onSuspendUserPressed()
  }

  const handleToggleACHPressed = () => {
    onToggleACHPressed()
  }
 
  const handleEditUserDataPressed = () => {
    onEditUserDataPressed()
  }

  const handleEditPersonalLimitsPressed = () => {
    onEditPersonalLimitsPressed()
  }
  
  const handleFreebetPressed = () => {
    onEditFreeBetPressed()
  }
  
  const handleProfitBoostPressed = () => {
    onEditProfitBoostPressed()
  }

  const handlePaymentInstrumentsPressed = () => {
    onPaymentInstrumentsPressed()
  }

  const handleSendEmailVerification = () => {
    sendEmailVerification()
  }

  const handleDeleteUserPrsssed = () => {
    onDeleteUserPressed()
  }


  return (
    <div className="user-actions">

      <p className='header-text'>
        Actions
      </p>

      <div className='divider-line' />

      <div className='buttons-row'>
        <button 
          className='action-button'
          onClick={handleSuspendUserPressed}
        >
            Suspend / Unsuspend
        </button>
        <button 
        className='action-button'
        onClick={handleManageMoneyPressed}
        >
            Edit Wallet
        </button>
      </div>

      <div className='buttons-row'>
        <button 
        className='action-button'
        onClick={handlePaymentInstrumentsPressed}
        >
            Payment Instruments
        </button>
        <button 
        className='action-button'
        onClick={handleToggleACHPressed}
        >
            Toggle ACH
        </button>
      </div>

      <div className='buttons-row'>
        <button 
        className='action-button'
        onClick={handleEditUserDataPressed}
        >
            Edit User
        </button>
        <button 
        className='action-button'
        onClick={handleSendEmailVerification}
        >
            {
               emailVerificationSending
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              ) : (
                "Send Verification Email"
              )
            }
            
        </button>
      </div>

      <div className='buttons-row'>
        <button 
        className='action-button'
        onClick={handleEditPersonalLimitsPressed}
        >
            Personal Limits
        </button>
        <button 
        className='action-button'
        onClick={handleFreebetPressed}
        >
            Free Bet
        </button>
      </div>
      
      <div className='buttons-row'>
        <button 
        className='action-button'
        onClick={handleProfitBoostPressed}
        >
            Profit Boost
        </button>
      </div>
      
      <div className='delete-user-container'>
        <button 
          className='delete-user-button'
          onClick={handleDeleteUserPrsssed}
        >
            Delete User
        </button>
      </div>
      
    </div>
  );
};

export default UserActions;

