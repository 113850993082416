import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../../CommonComponents/ToggleSwitch/ToggleSwitch";

import "./stylesPaymentToggle.css";
import createPrivateAdminPanelInstance from "../../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../../api/ApiRoutes";
import SaveButton from "../../../CommonComponents/SaveButton/SaveButton";
import { set } from "date-fns";

const PaymentToggle = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [finixEnabled, setFinixEnabled] = useState(false);
  const [nmiEnabled, setNmiEnabled] = useState(false);
  const [amount, setAmount] = useState("");
  const [processor0, setProcessor0] = useState("nmi");
  const [processor1, setProcessor1] = useState("finix");
  const [condition0, setCondition0] = useState("less");
  const [condition1, setCondition1] = useState("greater");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const updateEventCode = ({ event_code, is_active }) => {
    if (event_code === "finix") {
      setFinixEnabled(is_active);
    } else {
      setNmiEnabled(is_active);
    }
  };

  const savePaymentProcessor = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    if (!finixEnabled && !nmiEnabled) {
      setErrorMessage("Please enable at least one payment processor.");
      return;
    }

    setIsApiRunning(true);

    let params = {};

    if (!finixEnabled || !nmiEnabled) {
      params = {
        apple_pay_payment_processor: finixEnabled ? ["finix"] : ["nmi"],
      };
    } else {
      const apple_pay_payment_processor = [
        condition0 === "less" ? processor0 : processor1,
        condition1 === "greater" ? processor1 : processor0,
      ];

      params = {
        updated_threshold_value: amount,
        apple_pay_payment_processor,
      };
    }

    try {
      const response = await PrivateAdminPanelInstance.post(
        API_ROUTES.UPDATE_APPLE_PAY_PROVIDER_SETTINGS,
        params
      );

      setSuccessMessage("Payment processor settings saved successfully.");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      
    } catch (error) {
      setErrorMessage(error.response.data.message);
    } finally {
      setIsApiRunning(false);
    }
  };

  const getPaymentProcessor = async () => {
    setIsApiRunning(true);

    try {
      const response = await PrivateAdminPanelInstance.post(
        API_ROUTES.GET_APPLE_PAY_PROVIDER_SETTINGS
      );
      const data = response.data.data;

      setAmount(data.apple_pay_provider_threshold || "");
      setFinixEnabled(data.apple_pay_payment_processor.includes("finix"));
      setNmiEnabled(data.apple_pay_payment_processor.includes("nmi"));

      if (data.apple_pay_payment_processor[0]) {
        setProcessor0(
          data.apple_pay_payment_processor[0].charAt(0) +
            data.apple_pay_payment_processor[0].slice(1)
        );
      }

      if (data.apple_pay_payment_processor[1]) {
        setProcessor1(
          data.apple_pay_payment_processor[1].charAt(0) +
            data.apple_pay_payment_processor[1].slice(1)
        );
      }
    } catch (error) {
      console.log("error getting payment processor.", error);
    } finally {
      setIsApiRunning(false);
    }
  };

  const handleSelectCondition = (
    condition,
    setCondition,
    setOtherCondition
  ) => {
    setCondition(condition);
    setOtherCondition(condition === "greater" ? "less" : "greater");
  };

  const handleSetProcessor = (processor, setProcessor, setOtherProcessor) => {
    setProcessor(processor);
    setOtherProcessor(processor === "finix" ? "nmi" : "finix");
  };

  const handleSelectCondition0 = (value) => {
    handleSelectCondition(value, setCondition0, setCondition1);
  };

  const handleSelectCondition1 = (value) => {
    handleSelectCondition(value, setCondition1, setCondition0);
  };

  const handleSetProcessor0 = (value) => {
    handleSetProcessor(value, setProcessor0, setProcessor1);
  };

  const handleSetProcessor1 = (value) => {
    handleSetProcessor(value, setProcessor1, setProcessor0);
  };

  useEffect(() => {
    getPaymentProcessor();
  }, []);

  return (
    <div className="payment-toggle-container">
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <h2>Payment Processor</h2>
      <div className="toggle-section">
        <label>Finix</label>
        <ToggleSwitch
          status={finixEnabled}
          eventCode="finix"
          updateEventCode={updateEventCode}
        />
      </div>

      <div className="toggle-section">
        <label>NMI</label>
        <ToggleSwitch
          status={nmiEnabled}
          eventCode="nmi"
          updateEventCode={updateEventCode}
        />
      </div>

      {finixEnabled && nmiEnabled && (
        <div className="both-settings">
          <div className="condition-section">
            <label>If purchase is</label>
            <select
              value={condition0}
              onChange={(e) => handleSelectCondition0(e.target.value)}
            >
              <option value="less">less</option>
              <option value="greater">greater</option>
            </select>
            <label>than</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Amount"
            />
            <label>,</label>
            <label>payment is processed by</label>
            <select
              value={processor0}
              onChange={(e) => handleSetProcessor0(e.target.value)}
            >
              <option value="finix">Finix</option>
              <option value="nmi">NMI</option>
            </select>
          </div>
          <div className="condition-section">
            <label>And if purchase is</label>
            <select
              value={condition1}
              onChange={(e) => handleSelectCondition1(e.target.value)}
            >
              <option value="greater">greater</option>
              <option value="less">less</option>
            </select>
            <label>than</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Amount"
            />
            <label>,</label>
            <label>payment is processed by</label>
            <select
              value={processor1}
              onChange={(e) => handleSetProcessor1(e.target.value)}
            >
              <option value="finix">Finix</option>
              <option value="nmi">NMI</option>
            </select>
          </div>
        </div>
      )}
      <SaveButton
        isApiRunning={isApiRunning}
        callbackFunction={savePaymentProcessor}
      />
    </div>
  );
};

export default PaymentToggle;
