// third party imports
import React, { useState, useEffect } from "react";

// internal rebet imports
import "./stylesWhiteList.css";
import { SIDER_TABS } from "../../utils/Constants";
import Layout from "../CommonComponents/Layout/Layout";

// component imports
import RebetLoader from "../CommonComponents/RebetLoader/RebetLoader";
import WhiteListRow from "./WhiteListRow/WhiteListRow";
import createPrivateAdminPanelInstance from "../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../api/ApiRoutes";
import AddNewEventPopup from "./AddNewIpPopup/AddNewIpPopup";

// asset imports

const WhiteList = () => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [codesToShowInTable, setCodesToShowInTable] = useState([]);
  const [isAddNewEventsPopupVisible, setIsAddNewEventsPopupVisible] =
    useState(false);
  const [expandedAdminId, setExpandedAdminId] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  // use effects

  // api functions
  const getAdmins = async () => {
    setIsApiRunning(true);

    try {
      const response = await PrivateAdminPanelInstance?.post(
        API_ROUTES?.GET_ADMINS
      );
      const data = response?.data.data;
      setCodesToShowInTable(data);
      setIsApiRunning(false);
    } catch (error) {
      console.log("error getting admins", error);
    } finally {
      setIsApiRunning(false);
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);

  // helper functions
  const handleExpandAdmin = (adminId) => {
    setExpandedAdminId(expandedAdminId === adminId ? null : adminId);
  };

  const handlePopupVisible = (adminId, adminName) => {
    console.log("adminId", adminId);
    setSelectedAdmin({
      admin_id: adminId,
      admin_name: adminName,
    });
    setIsAddNewEventsPopupVisible(true);
  };

  return (
    <Layout selectedTab={SIDER_TABS?.STAFF_MANAGEMENT}>
      <div className="whitelist-body-container">
        <AddNewEventPopup
          isVisible={isAddNewEventsPopupVisible}
          onClosePressed={setIsAddNewEventsPopupVisible}
          getAdmins={getAdmins}
          selectedAdmin={selectedAdmin}
        />
        <div className="whitelist-table">
          <>
            <div className="head-controls">
              <div className="header-text-container">
                <p className="header-text">Whitelist</p>
              </div>
            </div>

            <div className="table-container">
              {isApiRunning ? (
                <RebetLoader height={"100px"} width={"100px"} />
              ) : (
                <>
                  <WhiteListRow is_header_row={true} code={{}} />

                  {codesToShowInTable &&
                    codesToShowInTable.map((user, index) => (
                      <WhiteListRow
                        key={index}
                        is_header_row={false}
                        data={user}
                        is_expanded={expandedAdminId === user.admin_id}
                        onExpand={() => handleExpandAdmin(user.admin_id)}
                        onPopupVisible={() =>
                          handlePopupVisible(user.admin_id, user.admin_name)
                        }
                      />
                    ))}
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default WhiteList;
