import React, { useEffect } from "react";

import ToggleSwitch from "../../../../../../CommonComponents/ToggleSwitch/ToggleSwitch";

import { PERSONAL_LIMITS } from "../../../../../../../utils/Constants";
import moneyFormatter from "../../../../../../../utils/MoneyFormatter";
import CashIcon from "../../../../../../../assets/icons/ic_cash.png";
import CoinIcon from "../../../../../../../assets/icons/ic_coin.png";
import RebetLoader from "../../../../../../CommonComponents/RebetLoader/RebetLoader";

// import { Container } from './styles';

function CasinoLimits({
  getUserPersonalLimit,
  userPersonalLimit,
  setTitle,
  setIsViewPersonalLimitsPopupVisible,
  setIsEditCasinoLimitsPopupVisible,
  isApiRunning,
  setEditingMode,
}) {
  const handleEditClick = (section) => {
    setEditingMode(true);
    switch (section) {
      case PERSONAL_LIMITS.CASINO_LIMITS:
        setTitle("Edit Casino Limits");
        setIsViewPersonalLimitsPopupVisible(false);
        setIsEditCasinoLimitsPopupVisible(true);
        break;
      default:
        break;
    }
  };

  console.log("userPersonalLimit", userPersonalLimit);

  useEffect(() => {
    getUserPersonalLimit(false);
  }, []);

  return (
    <div className="personal-limits-body">
      {isApiRunning ? (
        <RebetLoader />
      ) : (
        <>
          <div className="personal-limits-section-container">
            <div className="personal-limits-section-header">
              Casino Wager limits
              <span
                className="personal-limits-edit-button"
                onClick={() => handleEditClick(PERSONAL_LIMITS.CASINO_LIMITS)}
              >
                Edit
              </span>
            </div>
            <div className="personal-limits-section-body">
              <div className="personal-limits-section-item border-bottom">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max Casino wager amount of <br /> Rebet Coin per Day
                  </div>
                  <div className="personal-limits-section-item-value">
                    <img
                      src={CoinIcon}
                      alt="Coin Icon"
                      width={15}
                      height={15}
                    />
                    <span>
                      {userPersonalLimit.max_casino_coin_wagered_per_day !== false
                        ? moneyFormatter(
                            userPersonalLimit.max_casino_coin_wagered_per_day
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_casino_coin_wagered_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
              <div className="personal-limits-section-item">
                <div>
                  <div className="personal-limits-section-item-label">
                    Max Casino wager amount of <br /> Rebet Cash per Day
                  </div>
                  <div className="personal-limits-section-item-value">
                    <img
                      src={CashIcon}
                      alt="Cash Icon"
                      width={15}
                      height={15}
                    />
                    <span>
                      {userPersonalLimit.max_casino_cash_wagered_per_day !== false
                        ? moneyFormatter(
                            userPersonalLimit.max_casino_cash_wagered_per_day
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <ToggleSwitch
                  status={userPersonalLimit.max_casino_cash_wagered_per_day}
                  eventCode={"123"}
                  updateEventCode={() => {}}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CasinoLimits;
