import React, { useEffect, useState } from "react";

import "../FreeBetDataChart/stylesFreeBetDataChart.css";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { API_ROUTES } from "../../../../api/ApiRoutes";
import createPrivateAdminPanelInstance from "../../../../api/PrivateAdminPanelInstance";
import moneyFormatter from "../../../../utils/MoneyFormatter";
import RebetLoader from "../../../CommonComponents/RebetLoader/RebetLoader";
import { FREE_BET_TYPES, TIME_FRAMES } from "../../../../utils/Constants";
import DropDown from "../../../CommonComponents/DropDown/DropDown";
import useTrackingChartData from "../../../../hooks/useTrackingChartData";
import { formatNumber } from "chart.js/helpers";

function ProfitBoosDataChart() {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [byDay, setByDay] = useState([]);
  const [byMonth, setByMonth] = useState([]);
  const [overall, setOverall] = useState({});
  const [selectedDataType, setSelectedDataType] = useState(
    FREE_BET_TYPES.WAGERED
  );
  const [apiIsRunning, setApiIsRunning] = useState(false);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(TIME_FRAMES.DAY);

  const [kpis, setKpis] = useState([
    { title: "Profit Boost Paid Out", value: 0 },
    { title: "Number of Profit Boosts Placed", value: 0 },
    { title: "Amount of Rebet Cash Wagered on Profit Boost", value: 0 },
    { title: "Unplaced Profit Boosts", value: 0 },
  ]);

  const { data, options } = useTrackingChartData({
    selectedTimeFrame,
    byDay,
    byMonth,
    selectedDataType,
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const handleSetTimeFrame = (value) => {
    setSelectedTimeFrame(value);
  };

  const handleSetFreeBetType = (value) => {
    setSelectedDataType(value);
  };

  const getProfitBoostMetrics = async () => {
    setApiIsRunning(true);

    try {
      const response = await PrivateAdminPanelInstance?.post(
        API_ROUTES?.GET_PROFIT_BOOST_METRICS
      );

      const byday = response.data.data["used_profit_boost"]["by_day"];
      const byMonth = response.data.data["used_profit_boost"]["by_month"];
      const overall = response.data.data["used_profit_boost"]["overall"];
      const unusedFreeCash =
        response.data.data["unused_free_cash"]?.overall?.total ?? 0;

      setByDay(byday);
      setByMonth(byMonth);
      setOverall(overall);
      setKpis([
        {
          title: "Profit Boost Paid Out",
          value: `$${moneyFormatter(overall?.payout.total)}`,
        },
        {
          title: "Number of Profit Boosts Placed",
          value: formatNumber(overall?.count.total, "en", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        },
        {
          title: "Amount of Rebet Cash Wagered on Profit Boost",
          value: `$${moneyFormatter(overall?.wager.total)}`,
        },
        {
          title: "Unplaced Profit Boosts",
          value: formatNumber(unusedFreeCash, "en", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        },
      ]);
    } catch (error) {
      console.log("There was an error profit boost metrics");
    } finally {
      setApiIsRunning(false);
    }
  };

  useEffect(() => {
    getProfitBoostMetrics();
  }, []);

  return (
    <div id="free-bet-data-chart">
      {apiIsRunning ? (
        <RebetLoader height={"150px"} width={"150px"} />
      ) : (
        <>
          <div className="free-bet-data-chart-header">
            <div className="free-bet-data-chart-kpis">
              {kpis.map((kpi, index) => (
                <div
                  key={index}
                  className={`free-bet-data-chart-kpi ${
                    index === 0 ? "active" : ""
                  }`}
                >
                  <div className="free-bet-data-chart-kpi-title">
                    {kpi.title}
                  </div>
                  <div className="free-bet-data-chart-kpi-value">
                    {kpi.value}
                    {/* <div className={`free-bet-data-chart-kpi-percentage ${kpi.percentage >= 0 ? 'up' : 'down'}`}>
											{kpi.percentage > 0 ? `▲${kpi.percentage}%` : `▼${kpi.percentage}%`}
										</div> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="dropdowns-container">
              <DropDown
                drop_down_options={FREE_BET_TYPES}
                selected_value={selectedDataType}
                set_selected_value={handleSetFreeBetType}
              />
              <DropDown
                drop_down_options={TIME_FRAMES}
                selected_value={selectedTimeFrame}
                set_selected_value={handleSetTimeFrame}
              />
            </div>
          </div>
          <div className="free-bet-data-chart-body">
            <div className="left-content">
              <Bar data={data} options={options} />
            </div>
            <div className="right-content">
              <div className="stat">
                <div className="stat-title">
                  <div
                    className="circle"
                    style={{ backgroundColor: "#9B59B6" }}
                  ></div>
                  SportRadar
                </div>
                <div className="stat-value">
                  ${moneyFormatter(overall?.wager?.sportradar)}
                </div>
              </div>
              <div className="stat">
                <div className="stat-title">
                  <div
                    className="circle"
                    style={{ backgroundColor: "#71BF8A" }}
                  ></div>
                  DST
                </div>
                <div className="stat-value">
                  ${moneyFormatter(overall?.wager?.dst)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProfitBoosDataChart;
