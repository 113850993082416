import React, { useState, useEffect } from "react";
import { X } from "react-feather";

import "./stylesProfitBoostPopup.css";
import createPrivateAdminPanelInstance from "../../../../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../../../../api/ApiRoutes";
import RebetLoader from "../../../../../CommonComponents/RebetLoader/RebetLoader";

const ProfitBoostPopup = ({ isVisible, onClosePressed, userData }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    percentage: "",
    expiry_hours: "",
    min_parlay_legs: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user_id: userData.user_id,
      }));
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const clearForm = () => {
    setFormData({
      percentage: "",
      expiry_hours: "",
      min_parlay_legs: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsApiRunning(true);

    const params = {
      user_id: formData.user_id,
      percentage: formData.percentage,
      expiry_hours: formData.expiry_hours,
      min_parlay_legs: formData.min_parlay_legs,
    };

    try {
      await PrivateAdminPanelInstance?.post(
        API_ROUTES?.GIVE_USER_PROFIT_BOOST,
        params
      );

      setSuccessMessage("Profit Boost added successfully.");

      setTimeout(() => {
        setSuccessMessage("");
        clearForm();
      }, 3000);
    } catch (error) {
      console.error("Error in setting Profit boost:", error);
      setError("There was an error processing your request. Please try again.");
    } finally {
      setIsApiRunning(false);
    }
  };

  const handleClosePressed = () => {
    onClosePressed();
  };

  if (!isVisible) return null;

  return (
    <div className="suspend-user-popup profit-boost-popup">
      <div className="suspend-user-panel">
        <div className="suspend-user-header">
          <h2>Profit Boost</h2>
          <button className="close-button" onClick={handleClosePressed}>
            <X className="close-icon" />
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}

        <div className="profit-boost-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="percentage">Percentage:</label>
              <input
                type="number"
                id="percentage"
                name="percentage"
                value={formData.percentage}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="expiry_hours">Expiry hours:</label>
              <input
                type="number"
                id="expiry_hours"
                name="expiry_hours"
                value={formData.expiry_hours}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="min_parlay_legs">Minimum Parley Legs:</label>
              <input
                type="number"
                id="min_parlay_legs"
                name="min_parlay_legs"
                value={formData.min_parlay_legs}
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              className={`${isApiRunning ? "button-sending" : ""}`}
              disabled={isApiRunning}
            >
              {isApiRunning ? (
                <RebetLoader height={"50px"} width={"50px"} />
              ) : (
                "Add Profit Boost"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfitBoostPopup;
