import React from 'react';
import './stylesAltOddsTableRow.css';
import { formatDateDay, formatFromUnixTimestamp } from '../../../../../utils/DateFormatter'; // A função 'formatDatesArray' não é usada
import { format } from 'date-fns';


function AltOddsTableRow({
  is_header_row,
  data,
}) {
  if (data?.last_evaluated_key) return null;

  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>

      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Date" :
              <>
              {format(data?.timestamp, 'MMM d, yyyy h:mm a')} - UTC
              </>
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Alt-Odds setting" :
              data?.updated_status
          }
        </p>
      </div>

    </div>
  );
}

export default AltOddsTableRow;
