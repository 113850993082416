import React, { useState, useEffect } from "react";
import { X } from "react-feather";

import "./stylesFreeBetsPopup.css";
import createPrivateAdminPanelInstance from "../../../../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../../../../api/ApiRoutes";
import RebetLoader from "../../../../../CommonComponents/RebetLoader/RebetLoader";

const FreeBetsPopup = ({ isVisible, onClosePressed, userData }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    amount: "",
    exp_time_format: "hours",
    exp_time: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user_id: userData.user_id,
      }));
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const clearForm = () => {
    setFormData({
      amount: "",
      exp_time_format: "hours",
      exp_time: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsApiRunning(true);

    const params = {
      user_id: formData.user_id,
      amount: formData.amount,
      exp_time_format: formData.exp_time_format,
      exp_time: formData.exp_time,
    };

    try {
      await PrivateAdminPanelInstance?.post(
        API_ROUTES?.GIVE_USER_FREE_PICK,
        params
      );

      setSuccessMessage("Free bet added successfully.");

      setTimeout(() => {
        setSuccessMessage("");
        clearForm();
      }, 3000);
    } catch (error) {
      console.error("Error in setting free bets:", error);
      setError("There was an error processing your request. Please try again.");
    } finally {
      setIsApiRunning(false);
    }
  };

  const handleClosePressed = () => {
    onClosePressed();
  };

  if (!isVisible) return null;

  return (
    <div className="suspend-user-popup free-bets-popup">
      <div className="suspend-user-panel">
        <div className="suspend-user-header">
          <h2>Free Bet</h2>
          <button className="close-button" onClick={handleClosePressed}>
            <X className="close-icon" />
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}

        <div className="free-bets-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="amount">Amount:</label>
              <input
                type="number"
                id="amount"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="exp_time_format">Expiration Time Format:</label>
              <select
                id="exp_time_format"
                name="exp_time_format"
                value={formData.exp_time_format}
                onChange={handleChange}
              >
                <option value="hours">Hours</option>
                <option value="days">Days</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="exp_time">Expiration Time:</label>
              <input
                type="number"
                id="exp_time"
                name="exp_time"
                value={formData.exp_time}
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              className={`${isApiRunning ? "button-sending" : ""}`}
              disabled={isApiRunning}
            >
              {isApiRunning ? (
                <RebetLoader height={"50px"} width={"50px"} />
              ) : (
                "Add Free Bet"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FreeBetsPopup;
