import React from 'react';

import './stylesFTDListingTableRow.css'
import { formatFromUnixTimestamp } from '../../../../../utils/DateFormatter';

function FTDListingTableRow({
  is_header_row,
  individualStat,
}) {

  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Date" :
              formatFromUnixTimestamp(individualStat?.created_at)
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Email" :
              individualStat?.email
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Amount" :
              individualStat?.amount
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Referral Code" :
              individualStat?.referral_code
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Money Type" :
              individualStat?.money_type
          }
        </p>
      </div>
    </div>
  );
}

export default FTDListingTableRow;