// WhiteListRow.jsx
import React from "react";
import "./stylesWhiteListRow.css";
import { formatFromUnixTimestamp } from "../../../utils/DateFormatter";

function WhiteListRow({
  is_header_row,
  data,
  is_expanded,
  onExpand,
  onPopupVisible,
}) {
  return (
    <>
      <div className={`table-row ${is_header_row ? "table-header-row" : ""}`}>
        <div className="cell-small extra-small">
          <div className="cell-label">
            {is_header_row ? (
              ""
            ) : (
              <p onClick={onExpand} className="expand-button">
                {is_expanded ? "-" : "+"}
              </p>
            )}
          </div>
        </div>
        <div className="cell-small">
          <p className="cell-label">
            {is_header_row ? "Name" : data?.admin_name}
          </p>
        </div>
        <div className="cell-small">
          <p className="cell-label">
            {is_header_row
              ? "Created at"
              : formatFromUnixTimestamp(data?.created_at)}
          </p>
        </div>
        <div className="cell-small">
          <p className="cell-label">{is_header_row ? "Email" : data?.email}</p>
        </div>
        <div className="cell-small">
          <p className="cell-label">
            {is_header_row ? "Group" : data?.group_name}
          </p>
        </div>
        <div className="cell-small">
          <div className="cell-label">
            {is_header_row ? (
              "Action"
            ) : (
              <p className="add-ip" onClick={onPopupVisible}>
                +
              </p>
            )}
          </div>
        </div>
      </div>
      {is_expanded && !is_header_row && (
        <div className="expanded-row">
          <p className="expanded-row-title">Whitelisted IPs:</p>
          <div className="expanded-row-labels">
            {data.whitelisted_ips.map((ip, index) => (
              <span key={index}>{ip}</span>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default WhiteListRow;
