// src/utils/validation.js

/**
 * Validates the RebetCoins value based on certain conditions.
 *
 * @param {int} value - The value to be validated.
 * @param {boolean} switchStatus - The status of the switch.
 * @param {function} setError - The function to set the error message.
 * @returns {boolean} - Returns true if the validation passes, otherwise false.
 */
export const validateRebetCoins = (value, switchStatus, setError, min = 50, max = 50000) => {
  if ((switchStatus && (value < min || value > max)) || (switchStatus && !value && value !== 0)) {
    setError(`RebetCoins value must be between ${min} and ${max}`);
    return false;
  }
  setError('');
  return true;
};

/**
 * Validates the RebetCash value based on certain conditions.
 *
 * @param {int} value - The value to be validated.
 * @param {boolean} switchStatus - The status of the switch.
 * @param {function} setError - The function to set the error message.
 * @returns {boolean} - Returns true if the validation passes, otherwise false.
 */
export const validateRebetCash = (value, switchStatus, setError, min = 10, max = 5000) => {
  if ((switchStatus && (value < min || value > max)) || (switchStatus && !value && value !== 0)) {
    setError(`RebetCash value must be between ${min} and ${max}`);
    return false;
  }
  setError('');
  return true;
};
