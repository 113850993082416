import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import ToggleSwitch from "../../../../../../CommonComponents/ToggleSwitch/ToggleSwitch";
import "../stylesEditingMode.css";
import createPrivateAdminPanelInstance from "../../../../../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../../../../../api/ApiRoutes";
import RebetLoader from "../../../../../../CommonComponents/RebetLoader/RebetLoader";
import {
  validateRebetCash,
  validateRebetCoins,
} from "../../../../../../../utils/Validation";
import {
  handleRebetCashChange,
  handleRebetCoinChange,
  updateCashSwitch,
  updateCoinSwitch,
} from "../../../../../../../utils/PersonalLimits/Handlers";

function EditCasinoLimitsPopup({
  getUserPersonalLimit,
  userId,
  showPersonalLimits,
  limits,
}) {
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  const [rebetCoins, setRebetCoins] = useState(Number(limits.rebetCoins));
  const [rebetCash, setRebetCash] = useState(Number(limits.rebetCash));
  const [coinSwitch, setCoinSwitch] = useState(limits.rebetCoins);
  const [cashSwitch, setCashSwitch] = useState(limits.rebetCash);
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [rebetCoinsError, setRebetCoinsError] = useState("");
  const [rebetCashError, setRebetCashError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const editCasinoLimits = async () => {
    if (!userId) return;

    const isRebetCoinsValid = validateRebetCoins(
      rebetCoins,
      parseInt(coinSwitch),
      setRebetCoinsError,
      0
    );
    const isRebetCashValid = validateRebetCash(
      rebetCash,
      parseInt(cashSwitch),
      setRebetCashError,
      0
    );

    if (!isRebetCoinsValid || !isRebetCashValid) {
      return;
    }

    setIsApiRunning(true);
    const params = {
      user_id: userId,
      user_personal_limit: {
        max_casino_coin_wagered_per_day: coinSwitch
          ? Number(rebetCoins)
          : false,
        max_casino_cash_wagered_per_day: cashSwitch ? Number(rebetCash) : false,
      },
    };

    try {
      await PrivateAdminPanelInstance.post(
        API_ROUTES.SET_USERS_PERSONAL_LIMIT,
        params
      );
      await getUserPersonalLimit(true);

      setIsApiRunning(false);
      setSuccessMessage("Casino limits updated successfully.");

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.log("Error editing casino limits: ", error);
      setIsApiRunning(false);
    }
  };

  return (
    <div className="editing-mode">
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      <div className="personal-limits-section-container">
        <div className="personal-limits-section-body">
          <div className="personal-limits-section-item border-bottom">
            <div>
              <div className="personal-limits-section-item-label">
                Max Casino wager amout <br /> of Rebet Coins per day
                <ToggleSwitch
                  status={coinSwitch}
                  eventCode={"123"}
                  updateEventCode={() =>
                    updateCoinSwitch(setCoinSwitch, setRebetCoinsError)
                  }
                />
              </div>
              <div className="personal-limits-section-item-value">
                <NumericFormat
                  className={!coinSwitch ? "disabled" : ""}
                  value={rebetCoins}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  onValueChange={(value) =>
                    handleRebetCoinChange(
                      value,
                      setRebetCoins,
                      parseInt(coinSwitch),
                      setRebetCoinsError,
                      0
                    )
                  }
                  disabled={!coinSwitch}
                />
                {rebetCoinsError && (
                  <div className="error-message">{rebetCoinsError}</div>
                )}
              </div>
            </div>
          </div>
          <div className="personal-limits-section-item border-bottom">
            <div>
              <div className="personal-limits-section-item-label">
                Max Casino wager amout <br /> of Rebet Cash per day
                <ToggleSwitch
                  status={cashSwitch}
                  eventCode={"123"}
                  updateEventCode={() =>
                    updateCashSwitch(setCashSwitch, setRebetCashError)
                  }
                />
              </div>
              <div className="personal-limits-section-item-value">
                <NumericFormat
                  className={!cashSwitch ? "disabled" : ""}
                  value={rebetCash}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  onValueChange={(value) =>
                    handleRebetCashChange(
                      value,
                      setRebetCash,
                      parseInt(cashSwitch),
                      setRebetCashError,
                      0
                    )
                  }
                  disabled={!cashSwitch}
                />
                {rebetCashError && (
                  <div className="error-message">{rebetCashError}</div>
                )}
              </div>
            </div>
          </div>
          <div className="buttons">
            <button className="close-button" onClick={showPersonalLimits}>
              Back
            </button>
            <button
              className={`save-button ${isApiRunning ? "saving" : ""} ${
                rebetCashError || rebetCoinsError ? "disabled" : ""
              }`}
              disabled={isApiRunning || rebetCashError || rebetCoinsError}
              onClick={editCasinoLimits}
            >
              {isApiRunning ? (
                <RebetLoader height={"20px"} width={"20px"} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCasinoLimitsPopup;
