// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesSiderTab.css'

// component imports


// asset imports

const SiderTab = ({
  unselectedIcon, 
  selectedIcon, 
  tabName,
  selectedTab,
  onPress,
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects'

  // api functions

  // helper functions
  const handleTabClicked = () => {
    onPress()
  }

  return (
    <button 
      className={selectedTab === tabName ? "sider-tab-selected" : "sider-tab"}
      onClick={handleTabClicked}
    >

      <img 
        className='tab-icon'
        src={   
            selectedTab === tabName ? selectedIcon : unselectedIcon
        }
        alt='icon'
      />

      <div className={selectedTab === tabName ? 'tab-label-selected' : 'tab-label-unselected'}>
        {tabName}
      </div>

    </button>
  );
};

export default SiderTab;

