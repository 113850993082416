import { fromUnixTime, parseISO } from "date-fns";

const monthAbbreviations = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const daySuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const formatDateDay = (date, showYear = true) => {
  const dateObj = parseISO(date);
  const day = dateObj.getDate();
  const month = monthAbbreviations[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  return showYear
    ? `${month} ${day}, ${year}`
    : `${month} ${day}`;
};

const formatDateMonth = (date) => {
  const dateObj = parseISO(date);
  const month = monthAbbreviations[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  return `${month} ${year}`;
};

/**
 *
 * @param {Date[]} dates
 * @param {"day" | "month"} formatterType
 * @returns {string[]}
 */
const formatDatesArray = (dates, formatterType) => {
  return formatterType === "day"
    ? dates.map((date) => formatDateDay(date))
    : dates.map((date) => formatDateMonth(date));
};

const formatFromUnixTimestamp = (timestamp) => {
  if (!timestamp) return "N/A";
  
  const updatedAtDate = fromUnixTime(timestamp);
  const toIsoString = updatedAtDate.toISOString();
  const formattedDate = toIsoString.split("T")[0];

  return formatDateDay(formattedDate);
};

export {
  formatDatesArray,
  formatFromUnixTimestamp,
  formatDateDay,
  formatDateMonth,
};
