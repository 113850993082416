import React, { useEffect, useState } from 'react';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

import './stylesFTDListingTable.css'
import FTDListingTableRow from './FTDListingTableRow/FTDListingTableRow';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import DropdownMultipleCheckbox from '../../../CommonComponents/DropdownMultipleCheckbox/DropdownMultipleCheckbox';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';

function FTDListingTable({ referralCodes }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const optionsCode = referralCodes;
  
  const start_date = new Date();
  const end_date = new Date();

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [dateRange, setDateRange] = useState([start_date, end_date]);
  const [startDate, endDate] = dateRange;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [ftdListData, setFtdListData] = useState([]);


  const getFtdList = async (selectAll, referralCode, startDate = null, endDate = null) => {
    const start_date = startDate ? format(startDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');
    const end_date = endDate ? format(endDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');

    const params = {
      start_date,
      end_date,
      ...(selectAll || referralCode.length === 0 ? { all_referral_codes: true } : { referral_codes: referralCode })
    };
    
    setIsApiRunning(true)

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_FTD_LIST, params);

      const ftdListResponse = response;
      const ftdList = ftdListResponse.data.data;
      const sortedData = ftdList.sort((a, b) => {
        return parseFloat(a.created_at) - parseFloat(b.created_at);
      });

      setFtdListData(sortedData);

      setIsApiRunning(false);

    } catch (error) {
      setIsApiRunning(false);
      console.error("error getting referral codes", error);
    }

  }

  const handleCheckboxChange = (selectAll, newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
    getFtdList(selectAll, newSelectedOptions);
  };

  useEffect(() => {
    getFtdList(true, []);
  }, [])

  return (
    <div className="ftd-listing-table">

      <>
        <div className='head-controls'>

          <div className='header-text-container'>
            <p className='header-text'>
              FTD Listing
            </p>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText='Select Date Range'
              onChange={(update) => {
                setDateRange(update);
                const isnull = update.filter((date) => date === null)

                if (isnull.length === 0) {
                  getFtdList(false, selectedOptions, update[0], update[1]);
                }
                if (!update[0] && !update[1]) {
                  getFtdList(false, selectedOptions);
                }
              }}
              isClearable={true}
            />

            {!isApiRunning &&
              <DropdownMultipleCheckbox options={optionsCode}
                selectedOptions={selectedOptions} onChange={handleCheckboxChange} />
            }
          </div>


        </div>

        <div className='table-container'>

          {
            isApiRunning
              ? (
                <RebetLoader
                  height={'100px'}
                  width={'100px'} />
              )
              : (
                <>

                  <FTDListingTableRow
                    is_header_row={true}
                    individualStat={{}}
                  />


                  {ftdListData && ftdListData.map((individualStat, index) => (
                    <FTDListingTableRow
                      key={index}
                      is_header_row={false}
                      individualStat={individualStat}
                    />
                  ))}



                </>
              )
          }


        </div>
      </>

    </div>
  );
}

export default FTDListingTable;