import React, { useEffect, useState } from 'react';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

import './stylesIndividualStatsTable.css'
import IndividualStatsTableRow from './IndividualStatsTableRow/IndividualStatsTableRow';
import DatePicker from 'react-datepicker';
import { format, isWithinInterval } from 'date-fns';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';

function IndividualStatsTable({ referralCode }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);
  
  const start_date = new Date();
  const end_date = new Date();

  const [dateRange, setDateRange] = useState([start_date, end_date]);
  const [startDate, endDate] = dateRange

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [individualStatsToShowInTable, setindividualStatsToShowInTable] = useState([])


  const getFtdList = async (selectAll, referralCode, startDate = null, endDate = null) => {
    const start_date = startDate ? format(startDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');
    const end_date = endDate ? format(endDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');

    const params = {
      start_date,
      end_date,
      ...(selectAll || referralCode.length === 0 ? { all_referral_codes: true } : { referral_codes: referralCode })
    };

    setIsApiRunning(true)

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_FTD_LIST, params);

      const ftdListResponse = response;
      const ftdList = ftdListResponse.data.data;
      const sortedData = ftdList.sort((a, b) => {
        return parseFloat(a.created_at) - parseFloat(b.created_at);
      });

      setindividualStatsToShowInTable(sortedData);

      setIsApiRunning(false);

    } catch (error) {
      setIsApiRunning(false);
      console.error("error getting referral codes", error);
    }

  }

  useEffect(() => {
    getFtdList(false, referralCode);
  }, [])


  return (
    <div className="individual-stats-table">

      <>
        <div className='head-controls'>

          <div className='header-text-container'>
            <p className='header-text'>
              FTD Listing
            </p>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText='Select Date Range'
              onChange={(update) => {
                setDateRange(update);
                const isnull = update.filter((date) => date === null)

                if (isnull.length === 0) {
                  getFtdList(false, referralCode, update[0], update[1]);
                }
                if (!update[0] && !update[1]) {
                  getFtdList(false, referralCode);
                }
              }}
              isClearable={true}
            />
          </div>


        </div>

        <div className='table-container'>

          {
            isApiRunning ?
              <RebetLoader
                height={'70px'}
                width={'70p'}
              /> :

              <>

                <IndividualStatsTableRow
                  is_header_row={true}
                  individualStat={{}}
                />


                {individualStatsToShowInTable && individualStatsToShowInTable.map((individualStat, index) => (
                  <IndividualStatsTableRow
                    key={index}
                    is_header_row={false}
                    individualStat={individualStat}
                  />
                ))}



              </>

          }


        </div>
      </>

    </div>
  );
}

export default IndividualStatsTable;