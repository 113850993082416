// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesFinancialKPIs.css'

// component imports
import Purchases from './Purchases/Purchases';
import PrizeRedemptions from './PrizeRedemptions/PrizeRedemptions';
import Revenue from './Revenue/Revenue';
import CompositeFinancials from './CompositeFinancials/CompositeFinancials';
import TotalRedeemableAmount from './TotalRedeemableAmount/TotalRedeemableAmount';
import PaymentToggle from './PaymentToggle/PaymentToggle';

// asset imports

const FinancialKPIs = ({
  selectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects'

  // api functions

  // helper functions
 

  return (
    <div className="bet-kpis">
        <PaymentToggle />
        <CompositeFinancials />
        <TotalRedeemableAmount />
        <Revenue />
        <Purchases />
        <PrizeRedemptions />
    </div>
  );
};

export default FinancialKPIs;