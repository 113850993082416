// third party imports
import React, { useState } from "react";
import { X } from "react-feather";

// internal rebet imports
import "./stylesPersonalLimitsPopup.css";

// component imports
import RebetLoader from "../../../../../CommonComponents/RebetLoader/RebetLoader";
import EditTimeLimitsPopup from "./EditTimeLimitsPopup/EditTimeLimitsPopup";
import EditCurrencyLimitsPopup from "./EditCurrencyLimitsPopup/EditCurrencyLimitsPopup";
import EditWaggerLimitsPopup from "./EditWaggerLimitsPopup/EditWaggerLimitsPopup";
import EditLossLimitsPopup from "./EditLossLimitsPopup/EditLossLimitsPopup";
import PersonalLimits from "./PersonalLimits/PersonalLimits";
import createPrivateAdminPanelInstance from "../../../../../../api/PrivateAdminPanelInstance";
import { API_ROUTES } from "../../../../../../api/ApiRoutes";
import EditCasinoLimitsPopup from "./EditCasinoLimitsPopup/EditCasinoLimitsPopup";
import CasinoLimits from "./CasinoLimits/CasinoLimits";
// asset imports

const PersonalLimitsPopup = ({ isVisible, onClosePressed, userId }) => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [userPersonalLimit, setUserPersonalLimit] = useState({
    max_cash_bonus_per_day: false,
    max_cash_loss_per_day: false,
    max_cash_wagered_per_day: false,
    max_coin_loss_per_day: false,
    max_coin_purchase_per_day: false,
    max_coin_wagered_per_day: false,
    max_playing_time_per_day: false,
    max_playing_time_per_week: false,
    max_casino_cash_wagered_per_day: false,
    max_casino_coin_wagered_per_day: false,
  });

  const [title, setTitle] = useState("Limits");
  const [
    isViewPersonalLimitsPopupVisible,
    setIsViewPersonalLimitsPopupVisible,
  ] = useState(true);
  const [isEditTimeLimitsPopupVisible, setIsEditTimeLimitsPopupVisible] =
    useState(false);
  const [
    isEditCurrencyLimitsPopupVisible,
    setIsEditCurrencyLimitsPopupVisible,
  ] = useState(false);
  const [isEditWaggerLimitsPopupVisible, setIsEditWaggerLimitsPopupVisible] =
    useState(false);
  const [isEditLossLimitsPopupVisible, setIsEditLossLimitsPopupVisible] =
    useState(false);
  const [isEditCasinoLimitsPopupVisible, setIsEditCasinoLimitsPopupVisible] =
    useState(false);
  const [activeTab, setActiveTab] = useState("default");
  const [editingMode, setEditingMode] = useState(false);

  // use effects'

  // api functions

  const getUserPersonalLimit = async (shouldUpdateLockedLimit = false) => {
    if (!userId) return;

    setIsApiRunning(true);

    const params = {
      user_id: userId,
    };

    try {
      const response = await PrivateAdminPanelInstance.post(
        API_ROUTES.USERS_PERSONAL_LIMIT,
        params
      );
      const data = response.data.data;

      setUserPersonalLimit(data);

      const allKeysAreFalse = Object.values(data).every(
        (value) => value === false
      );

      if (allKeysAreFalse && shouldUpdateLockedLimit) {
        await lockPersonalLimit(userId, false);
      }

      setIsApiRunning(false);
    } catch (error) {
      console.log("Error getting user personal limits: ", error);
    }

    setIsApiRunning(false);
  };

  const lockPersonalLimit = async (userId, lockStatus) => {
    if (!userId) return;

    const params = {
      user_id: userId,
      is_user_limit_locked: lockStatus,
    };

    try {
      await PrivateAdminPanelInstance.post(
        API_ROUTES.UPDATE_USER_LOCKED_LIMIT,
        params
      );
    } catch (error) {
      console.log("Error locking personal limits: ", error);
    }
  };

  // helper functions
  const showPersonalLimits = () => {
    setIsViewPersonalLimitsPopupVisible(true);
    setIsEditTimeLimitsPopupVisible(false);
    setIsEditCurrencyLimitsPopupVisible(false);
    setIsEditWaggerLimitsPopupVisible(false);
    setIsEditLossLimitsPopupVisible(false);
    setIsEditCasinoLimitsPopupVisible(false);
    setEditingMode(false);
    setActiveTab("default");
    setTitle("Limits");
  };
  const handleClosePressed = () => {
    showPersonalLimits();
    onClosePressed();
  };

  if (!isVisible) return null;

  return (
    <div className="suspend-user-popup">
      <div className="suspend-user-panel">
        <div className="suspend-user-header">
          <div className="suspend-user-header-label">{title}</div>
          <button className="close-button" onClick={handleClosePressed}>
            <X className="close-icon" />
          </button>
        </div>
        {!editingMode && (
          <div className="personal-limits-tabs">
            <div
              className={`personal-limits-tab ${
                activeTab === "default" ? "active" : ""
              }`}
              onClick={() => setActiveTab("default")}
            >
              Default
            </div>
            <div
              className={`personal-limits-tab ${
                activeTab === "casino" ? "active" : ""
              }`}
              onClick={() => setActiveTab("casino")}
            >
              Casino
            </div>
          </div>
        )}
        {activeTab === "default" && (
          <>
            {isEditTimeLimitsPopupVisible && (
              <EditTimeLimitsPopup
                getUserPersonalLimit={getUserPersonalLimit}
                userId={userId}
                limits={{
                  timeAmount: userPersonalLimit.max_playing_time_per_day,
                  daysPerWeek: userPersonalLimit.max_playing_time_per_week,
                }}
                showPersonalLimits={showPersonalLimits}
              />
            )}
            {isEditCurrencyLimitsPopupVisible && (
              <EditCurrencyLimitsPopup
                getUserPersonalLimit={getUserPersonalLimit}
                userId={userId}
                limits={{
                  rebetCash: userPersonalLimit.max_cash_bonus_per_day,
                  rebetCoins: userPersonalLimit.max_coin_purchase_per_day,
                }}
                showPersonalLimits={showPersonalLimits}
              />
            )}
            {isEditWaggerLimitsPopupVisible && (
              <EditWaggerLimitsPopup
                getUserPersonalLimit={getUserPersonalLimit}
                userId={userId}
                limits={{
                  rebetCash: userPersonalLimit.max_cash_wagered_per_day,
                  rebetCoins: userPersonalLimit.max_coin_wagered_per_day,
                }}
                showPersonalLimits={showPersonalLimits}
              />
            )}
            {isEditLossLimitsPopupVisible && (
              <EditLossLimitsPopup
                getUserPersonalLimit={getUserPersonalLimit}
                userId={userId}
                limits={{
                  rebetCash: userPersonalLimit.max_cash_loss_per_day,
                  rebetCoins: userPersonalLimit.max_coin_loss_per_day,
                }}
                showPersonalLimits={showPersonalLimits}
              />
            )}
            {isViewPersonalLimitsPopupVisible && (
              <PersonalLimits
                getUserPersonalLimit={getUserPersonalLimit}
                userId={userId}
                userPersonalLimit={userPersonalLimit}
                setTitle={setTitle}
                setIsViewPersonalLimitsPopupVisible={
                  setIsViewPersonalLimitsPopupVisible
                }
                setIsEditTimeLimitsPopupVisible={
                  setIsEditTimeLimitsPopupVisible
                }
                setIsEditCurrencyLimitsPopupVisible={
                  setIsEditCurrencyLimitsPopupVisible
                }
                setIsEditWaggerLimitsPopupVisible={
                  setIsEditWaggerLimitsPopupVisible
                }
                setIsEditLossLimitsPopupVisible={
                  setIsEditLossLimitsPopupVisible
                }
                setIsEditCasinoLimitsPopupVisible={
                  setIsEditCasinoLimitsPopupVisible
                }
                setIsApiRunning={setIsApiRunning}
                setUserPersonalLimit={setUserPersonalLimit}
                setEditingMode={setEditingMode}
              />
            )}
          </>
        )}
        {activeTab === "casino" && (
          <>
            {isEditCasinoLimitsPopupVisible && (
              <EditCasinoLimitsPopup
                getUserPersonalLimit={getUserPersonalLimit}
                userId={userId}
                limits={{
                  rebetCash: userPersonalLimit?.max_casino_cash_wagered_per_day ?? 0,
                  rebetCoins: userPersonalLimit?.max_casino_coin_wagered_per_day ?? 0,
                }}
                showPersonalLimits={showPersonalLimits}
              />
            )}
            {isViewPersonalLimitsPopupVisible && (
              <CasinoLimits
                getUserPersonalLimit={getUserPersonalLimit}
                setTitle={setTitle}
                userPersonalLimit={userPersonalLimit}
                setIsViewPersonalLimitsPopupVisible={
                  setIsViewPersonalLimitsPopupVisible
                }
                setIsEditCasinoLimitsPopupVisible={
                  setIsEditCasinoLimitsPopupVisible
                }
                setIsApiRunning={setIsApiRunning}
                setUserPersonalLimit={setUserPersonalLimit}
                setEditingMode={setEditingMode}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalLimitsPopup;
